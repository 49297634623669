import React, { useState } from 'react'
import ColorParser from 'color'
import { ChromePicker } from 'react-color'

import Flex from '../Flex'
import ReadOnlyInput from '../ReadOnlyInput'
import Lock from '../Lock'
import Label from '../Label'
import Button from '../../elements/Button'

const Color = ({
  value,
  active: locked,
  label,
  onLock,
  onChange,
  showLock = true
}) => {
  const [showDropdown, setDropdown] = useState(false)

  const color = ColorParser(value)
  const hsl = color.hsl()
  const colorObject = {
    h: hsl.color[0],
    s: hsl.color[1],
    l: hsl.color[2],
    a: hsl.valpha
  }

  return (
    <Flex py={1} alignItems="center" style={{ position: 'relative' }}>
      {showLock && <Lock bg="transparent" active={locked} onClick={onLock} />}
      <Label>{label}</Label>
      {showDropdown ? (
        <>
          <div
            style={{
              position: 'fixed',
              top: '0px',
              right: '0px',
              bottom: '0px',
              left: '0px'
            }}
            onClick={() => setDropdown(false)}
          />
          <Button
            bg={value}
            border="1px solid transparent"
            p={2}
            mr={1}
            borderRadius={3}
            value={value}
            onClick={() => setDropdown(false)}
          />
          <ReadOnlyInput type="text" defaultValue={value} />
          <div
            style={{
              position: 'absolute',
              top: '5px',
              left: '50px',
              zIndex: 2
            }}
          >
            <ChromePicker
              color={colorObject}
              onChange={newValue => {
                const { r, g, b, a } = newValue.rgb
                const hsla = ColorParser({ r, g, b })
                  .alpha(a)
                  .hsl()
                  .string()

                const event = { target: { value: hsla } }
                onChange(event)
              }}
            />
          </div>
        </>
      ) : (
        <Flex width={1}>
          <Button
            bg={value}
            border={'1px solid transparent'}
            py={2}
            borderRadius={3}
            value={value}
            onClick={() => setDropdown(true)}
          />
          <ReadOnlyInput
            type="text"
            textAlign="left"
            fontSize={12}
            value={value}
            onChange={newValue => {
              const event = { target: { value: newValue.hex } }
              onChange(event)
            }}
            onClick={() => setDropdown(true)}
          />
        </Flex>
      )}
    </Flex>
  )
}

Color.defaultProps = {
  label: 'Color'
}

export default Color
