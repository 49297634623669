const randomHex = require('random-hex-color')
const chroma = require('chroma-js')
const sample = require('lodash.sample')
const adaptiveGradient = require('adaptive-gradient').default
const { randomInt } = require('../util')
const randomBoxShadow = require('./box-shadow')

const textAlign = sample(['center', 'right', 'justify', 'left'])

const content = [
  ' Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
  'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?'
]

const textDecorationStyle = sample([
  'solid',
  'double',
  'dotted',
  'dashed',
  'wavy'
])

const textDecorationLine = sample([
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'underline',
  'overline',
  'line-through',
  'blink'
])

const backgroundImageRepeat = sample([
  'no-repeat',
  'no-repeat',
  'no-repeat',
  'no-repeat',
  'no-repeat',
  'repeat',
  'repeat-x',
  'repeat-y',
  'space'
])

const backgroundSize = sample(['cover', 'contain'])

const backgroundPositionX = sample(['left', 'center', 'right', '25%', '75%'])

const backgroundPositionY = sample(['top', 'center', 'bottom', '25%', '75%'])

const blendModes = [
  'normal',
  'multiply',
  'screen',
  'overlay',
  'darken',
  'lighten',
  'color-dodge',
  'color-burn',
  'hard-light',
  'soft-light',
  'difference',
  'exclusion',
  'hue',
  'saturation',
  'color',
  'luminosity'
]

const blendMode = sample(blendModes)

/*
 
  size: [width, height]

  outer borderRadius 
        -right
        -left
        -bottom
        -top

  inner borderRadius 

  outer border style
  outer border width
  outer border color

  inner border style
  inner border width
  inner border color

  wrapper bg
  wrapper padding
  wrapper boxShadow

  avatarImg
  
  

*/

const displays = ['block']
const display = sample(displays)

const borderStyles = [
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'hidden',
  'dotted',
  'dashed',
  'solid',
  'double',
  'groove',
  'ridge',
  'inset',
  'outset'
]

const images = [
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  'https://mrmrs.cc/photos/u/001.jpg',
  'https://mrmrs.cc/photos/u/002.jpg',
  'https://mrmrs.cc/photos/u/003.jpg',
  'https://mrmrs.cc/photos/u/004.jpg',
  'https://mrmrs.cc/photos/u/005.jpg',
  'https://mrmrs.cc/photos/u/006.jpg',
  'https://mrmrs.cc/photos/u/007.jpg',
  'https://mrmrs.cc/photos/u/008.jpg',
  'https://mrmrs.cc/photos/u/009.jpg',
  'https://mrmrs.cc/photos/u/010.jpg',
  'https://mrmrs.cc/photos/u/011.jpg',
  'https://mrmrs.cc/photos/u/012.jpg',
  'https://mrmrs.cc/photos/u/013.jpg',
  'https://mrmrs.cc/photos/u/014.jpg',
  'https://mrmrs.cc/photos/u/015.jpg',
  'https://mrmrs.cc/photos/u/016.jpg',
  'https://mrmrs.cc/photos/u/017.jpg',
  'https://mrmrs.cc/photos/u/018.jpg',
  'https://mrmrs.cc/photos/u/019.jpg',
  'https://mrmrs.cc/photos/u/020.jpg',
  'https://mrmrs.cc/photos/u/021.jpg',
  'https://mrmrs.cc/photos/u/022.jpg',
  'https://mrmrs.cc/photos/u/023.jpg',
  'https://mrmrs.cc/photos/u/024.jpg',
  'https://mrmrs.cc/photos/u/025.jpg',
  'https://mrmrs.cc/photos/u/026.jpg',
  'https://mrmrs.cc/photos/u/027.jpg',
  'https://mrmrs.cc/photos/u/028.jpg'
]

const generateDiv = color => {
  const shadowBool = randomInt(0, 1)
  var boxShadow = 'none'
  if (shadowBool > 0) {
    boxShadow = randomBoxShadow()
  }

  color = color || randomHex()

  const outerRadius = randomInt(0, 7)

  const radiusBool = randomInt(0, 10)

  const backgroundImage = sample(images)
  let outerRadiusMax = outerRadius
  let outerRadiusMin = outerRadius
  let innerRadiusMax = outerRadius
  let innerRadiusMin = outerRadius

  if (radiusBool > 5) {
    outerRadiusMax = randomInt(0, 8)
    outerRadiusMin = randomInt(0, 8)
    innerRadiusMax = randomInt(0, 8)
    innerRadiusMin = randomInt(0, 8)
  }

  const borderWidth = randomInt(0, 8)
  const outerBorderWidth = randomInt(0, 6)

  const size = randomInt(32, 80)
  const padding = randomInt(0, 8)
  const margin = randomInt(0, 32)

  const borderStyle = sample(borderStyles)
  const fontWeight = sample([
    100,
    200,
    300,
    400,
    400,
    400,
    500,
    500,
    500,
    600,
    600,
    600,
    700,
    700,
    700,
    800,
    800,
    800,
    900,
    900,
    900,
    900,
    900
  ])
  const fontSize = randomInt(12, 32)

  const boxSizing = sample(['border-box', 'content-box'])

  const div = {
    boxSizing: boxSizing,
    display: display,
    fontWeight: fontWeight,
    alignItems: 'center',
    parentBg: randomHex(),
    color: color, // set color for wrapper border
    borderColor: color, // set border color for image
    borderStyle: borderStyle,
    outerBorderStyle: borderStyle,
    bg: randomHex(), // set background of wrapper color
    //startColorHinting,
    //endColor: '#' + endColor,
    //endColorHinting,
    spread: 80,
    borderWidth: borderWidth,
    outerBorderWidth: borderWidth > 0 ? 0 : outerBorderWidth,
    padding: padding,
    maxWidth: '34',
    paddingRight: padding,
    paddingLeft: padding,
    paddingTop: padding,
    paddingBottom: padding,
    marginRight: margin,
    marginLeft: margin,
    marginTop: margin,
    marginBottom: margin,
    topRightRadius: outerRadiusMin,
    topLeftRadius: outerRadiusMax,
    bottomRightRadius: outerRadiusMax,
    bottomLeftRadius: outerRadiusMin,
    mirrorTopRightRadius: outerRadiusMax,
    mirrorTopLeftRadius: outerRadiusMin,
    mirrorBottomRightRadius: outerRadiusMin,
    mirrorBottomLeftRadius: outerRadiusMax,
    boxShadow: boxShadow,
    borderTopWidth: '',
    borderTopStyle: '',
    borderTopColor: randomHex(),
    borderBottomWidth: '',
    borderBottomStyle: '',
    borderBottomColor: randomHex(),
    borderLeftWidth: '',
    borderLeftStyle: '',
    borderLeftColor: randomHex(),
    borderRightWidth: '',
    borderRightStyle: '',
    borderRightColor: randomHex(),
    borderImageSource: '',
    borderImageSlice: '',
    borderImageWidth: '',
    borderImageOutset: '',
    borderImageRepeat: '',
    boxShadowSpread: 2,
    boxShadowBlur: 8,
    boxShadowColor: 'rgba(0,0,0,.15)',
    boxShadowOffsetX: 2,
    boxShadowOffsetY: 2,
    boxShadowInset: sample([false, true]),
    outlineStyle: '',
    outlineWidth: '',
    outlineColor: '',
    text: sample(content),
    opacity: 100,
    fontSize: fontSize,
    fontWeight: '',
    textAlign: textAlign,
    textShadow: 'center',
    lineHeight: 150,
    letterSpacing: 0,
    textTransform: 'none',
    textShadowBlur: '0',
    textShadowOffsetX: '0',
    textShadowOffsetY: '0',
    textShadowColor: '#000',
    fontStyle: '',
    whiteSpace: '',
    backgroundImage: backgroundImage,
    backgroundImageRepeat: backgroundImageRepeat,
    backgroundImageY: backgroundPositionY,
    backgroundImageX: backgroundPositionX,
    backgroundSize: backgroundSize,
    animationDelay: '',
    animationDirection: '',
    animationDuration: '',
    animationFillMode: '',
    animationIterationCount: '',
    animationName: '',
    animationPlayState: '',
    animationTimingFunction: '',
    maskClip: '',
    maskComposite: '',
    maskImage: '',
    maskMode: '',
    maskOrigin: '',
    maskRepeat: '',
    maskSize: '',
    maskType: '',
    mixBlendMode: blendMode,
    bgMixBlendMode: blendMode,
    blur: 0,
    fontStyle: 'normal',
    textDecorationColor: '#000',
    textDecorationLine: textDecorationLine,
    textDecorationStyle: textDecorationStyle
  }

  return {
    ...div
    //backgroundImage: toGradient(gradient)
  }
}

module.exports = generateDiv
