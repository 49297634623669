/** @jsx jsx */
import { jsx } from 'theme-ui'

export default ({ children, ...props }) => (
  <h4
    sx={{
      fontSize: 12,
      mt: 0,
      mb: 2,
      color: '#343434',
      letterSpacing: '0.05em',
      textTransform: 'uppercase',
      display: ['none', 'block']
    }}
    {...props}
  >
    {children}
  </h4>
)
