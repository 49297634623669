import styled from '@emotion/styled'
import {
  space,
  width,
  display,
  fontSize,
  fontWeight,
  lineHeight,
  borders,
  borderColor,
  borderRadius,
  color
} from 'styled-system'

const ReadOnlyInput = styled('input')(
  space,
  width,
  display,
  fontSize,
  fontWeight,
  lineHeight,
  color,
  borders,
  borderColor,
  borderRadius,
  {
    boxSizing: 'border-box',
    ':focus': { outline: 'none', borderColor: 'transparent' },
    cursor: 'pointer'
  }
)

ReadOnlyInput.defaultProps = {
  bg: 'transparent',
  borderColor: 'transparent'
}

export default ReadOnlyInput
