import React from 'react'

import Button from '../elements/Button'
import Svg from '../elements/Svg'
import Path from '../elements/Path'

const Lock = ({ active, ...props }) => {
  return (
    <Button
      px={0}
      pr={2}
      pl={0}
      py={0}
      mb={0}
      mt={0}
      color="black"
      opacity={active ? 1 : 0.3}
      display="inline-flex"
      alignItems="center"
      justifyContent="flex-start"
      border="0px solid transparent"
      {...props}
    >
      <Svg viewBox="0 0 32 32" width="10px" height="10px" color="inherit">
        <Path
          fill="currentColor"
          d="M22 16 L22 12 A6 6 0 0 0 10 12 L10 16 z M4 16 L6 16 L6 12 A10 10 0 0 1 26 12 L26 16 L28 16 L28 30 L4 30 z"
        ></Path>
      </Svg>
    </Button>
  )
}

Lock.defaultProps = {
  mr: 1
}

export default Lock
