const { randomInt, randomBool } = require('../util')

const generateShadow = () => {
  return {
    inset: randomBool(1 / 5),
    offsetX: randomInt(0, 10),
    offsetY: randomInt(0, 15),
    blur: randomInt(0, 15),
    spread: randomInt(0, 15),
    color: `hsla(0, 0%, 0%, 0.${randomInt(0, 2)}${randomInt(1, 9)})`
  }
}

generateShadow.toBoxShadow = boxShadow => {
  return [
    boxShadow.inset && 'inset',
    boxShadow.offsetX + 'px',
    boxShadow.offsetY + 'px',
    boxShadow.blur + 'px',
    boxShadow.spread + 'px',
    boxShadow.color
  ]
    .filter(Boolean)
    .join(' ')
}

module.exports = generateShadow
