import React from 'react'

export default ({ children }) => (
  <span
    css={{
      display: 'none'
    }}
  >
    {children}
  </span>
)
